import PropTypes from 'prop-types';
import React, { useState,useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import { Link } from "react-router-dom";



import user1 from "../../assets/images/users/user-4.jpg";
// import images
import es1 from "../../assets/images/services-icon/es1.png";
//import es2 from "../../assets/images/services-icon/es2.png";
import es3 from "../../assets/images/services-icon/es3.png";
import es4 from "../../assets/images/services-icon/es4.png";
//import dp from "../../assets/images/services-icon/dp.svg";

import "chartist/dist/scss/chartist.scss";

import jwtDecode from 'jwt-decode';

//i18n
import { withTranslation } from "react-i18next";

import axios from "axios";
import { BASE_URL } from "../../global";

//Calendar start

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import listPlugin from '@fullcalendar/list';

import moment from 'moment';


//import DeleteModal from "./DeleteModal";
//css
import "@fullcalendar/bootstrap";
import Loader from "react-spinner-loader"

import { DatePicker, DateRangePicker, Stack } from 'rsuite';

const { allowedDays } = DateRangePicker;
//Calendar End

const Dashboard = props => {
  //Page Title start
  document.title = "Home";
  // page title end

  //Card Detail Start
  const [currencyType, setCurrency] = useState('SGD');
  const [LastPendingPayment, setLastPendingPayment] = useState(0);
  const [lastPendingPaymentAmount, setlastPendingPaymentAmount] = useState(0);
  const [OutStandingMark, setOutStandingMark] = useState(0);
  const [PendingAmount, setPendingAmount] = useState(0);
  const [UnplannedLesson, setUnplannedLesson] = useState(0);
  const [WeekSession, setWeekSession] = useState(0);
  const [YearSession, setYearSession] = useState(0);
  const [PaymentFrequency, setPaymentFrequency] = useState('');

  const [CalfromDate, setCalFromDate] = useState(null);
  const [CaltoDate, setCalToDate] = useState(null);
  const [TempdateValue, setTempDateValue] = useState([null, null]);
  const [dateValue, setDateValue] = useState([null, null]);
  const handleDateChange = value => {

    // Do something with the selected date range
    setDateValue(value);
    setTempDateValue(value);
  }
  const [loader, setLoader] = useState(false)
  const [events, setEvent] = useState([]);
  
  const [selectedTempDate, setSelectedTempDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef(null);
  const initialDay = parseInt(localStorage.getItem("initialDay")) || 0;
  const [firstDayOption, setFirstDayOption] = useState(initialDay);
  
  const storedView = localStorage.getItem("SelectedView") || 'true';
  const initialIsWeekView = storedView === 'true'; // Convert the string to a boolean
  const [IsWeekView, setIsWeekView] = useState(initialIsWeekView);

  const handleDatesSet = (dateInfo) => {
    
    if (dateInfo.view.type === 'dayGridWeek') {
      setIsWeekView(true);
      
    } else if(dateInfo.view.type === 'dayGridDay'){
      setIsWeekView(false);
     
    } else {
      setIsWeekView(true);
      
    }
   

    setCalFromDate(dateInfo.startStr);
    setCalToDate(dateInfo.endStr);

  };


  //Card Details End

 

  // function decodeAccessToken(token) {
  //   try {
  //     const decoded = jwtDecode(token);
  //     return { success: true, data: decoded };
  //   } catch (error) {
  //     return { success: false, error: error.message };
  //   }
  // }
  


  const authUser = JSON.parse(localStorage.getItem('authUser')) || null;

  const navigate = useNavigate();

  useEffect(() => {
    //Reset session storage start
    sessionStorage.removeItem('session');
    //Reset session storage end


    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
      axios.get(BASE_URL+'home/cardDetails').then(responseCard => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties


        if (responseCard.data != '') {
          if (responseCard.data.currency !== '') {
            setCurrency(responseCard.data.currency);

          } else {
            setCurrency('SGD');
          }

          if (responseCard.data.lastPendingPayment !== '') {
            setLastPendingPayment(responseCard.data.lastPendingPayment);

          } else {
            setLastPendingPayment(0);
          }

          if (responseCard.data.lastPendingPaymentAmount !== '') {
            setlastPendingPaymentAmount(responseCard.data.lastPendingPaymentAmount);

          } else {
            setlastPendingPaymentAmount(0);
          }

          if (responseCard.data.outStandingMark !== '') {
            setOutStandingMark(responseCard.data.outStandingMark);

          } else {
            setOutStandingMark(0);
          }

          if (responseCard.data.lastPendingPaymentAmount !== '') {
            //setPendingAmount(responseCard.data.pending_amount);
            setPendingAmount(responseCard.data.lastPendingPaymentAmount);
          } else {
            setPendingAmount(0);
          }

          if (responseCard.data.unplannedLesson !== '') {
            setUnplannedLesson(responseCard.data.unplannedLesson);

          } else {
            setUnplannedLesson(0);
          }


          if (responseCard.data.weekSession !== '') {
            setWeekSession(responseCard.data.weekSession);

          } else {
            setWeekSession(0);
          }
          if (responseCard.data.yearSession !== '') {
            setYearSession(responseCard.data.yearSession);

          } else {
            setYearSession(0);
          }

          if (responseCard.data.paymentFrequency !== '') {
            setPaymentFrequency(responseCard.data.paymentFrequency);

          } else {
            setPaymentFrequency('');
          }





        } else {
          console.error("Home card details not get from database.");
        }

      })
        .catch(error => {
          
          if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
            localStorage.clear();
            navigate("/login");
          }

        });


    }
    else {
      navigate("/login");
    }


  }, []);

  function formatTime(time) {
    const momentTime = moment(time, "HH:mm:ss");
    const hours = momentTime.format("hA");
    const minutes = momentTime.minutes();
    return minutes > 0 ? momentTime.format("h:mma") : hours;
}



  const fetchData = async () => {
    if (authUser && authUser != "" && authUser != null) {

      let datesArray = [];
      
      setDateValue([new Date(moment(CalfromDate, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')), new Date(moment(CaltoDate, 'YYYY-MM-DDTHH:mm:ssZ').subtract(1, 'days').format('YYYY-MM-DD'))]);
      setSelectedDate(new Date(moment(CaltoDate, 'YYYY-MM-DDTHH:mm:ssZ').subtract(1, 'days').format('YYYY-MM-DD')));
   
    localStorage.setItem("CalenderFromDate",  CalfromDate);
    localStorage.setItem("CalenderToDate",  CaltoDate);
    localStorage.setItem("SelectedView",  IsWeekView);
    localStorage.setItem("initialDay",  firstDayOption);
    let start_date= moment(CalfromDate, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD');
     let end_date= moment(CaltoDate, 'YYYY-MM-DDTHH:mm:ssZ').subtract(1, 'days').format('YYYY-MM-DD');
      let currentDate = moment(start_date);
      let endDate = moment(end_date);

      while (currentDate <= endDate) {
        datesArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.clone().add(1, 'days');
      }
      setLoader(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
      axios.post(BASE_URL+'home/calendarDetails', {
        start_date: moment(CalfromDate, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
        end_date: moment(CaltoDate, 'YYYY-MM-DDTHH:mm:ssZ').subtract(1, 'days').format('YYYY-MM-DD'),
      }).then(responseCalendar => {
       
        if (responseCalendar.data.session && responseCalendar.data.session.length > 0) {

          setLoader(false);
          const calendarEvents = [];

          const event_dates = [];

          responseCalendar.data.session.forEach((sessionData, index) => {
            event_dates.push(moment(sessionData.ses_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD'));

            calendarEvents.push({
              id: index,
              title: sessionData.sest_name,
              start: moment(sessionData.ses_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD') + 'T' + sessionData.ses_start_time,
              end: moment(sessionData.ses_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD') + 'T' + sessionData.ses_end_time,

              start_time: formatTime(sessionData.ses_start_time),
              end_time: formatTime(sessionData.ses_end_time),
              avatar: user1, // URL to avatar image
              session: sessionData.pk_ses_key,
              sest_is_holidays: sessionData.sest_is_holidays,
              student: sessionData.tbl_student_enrolment,
              reAssignStudent: sessionData.tbl_reassignment,
              status: sessionData.ses_status,
              session_cmpleted: sessionData.ses_is_completed,
            });
          });

          const notInEventDate = [];
          // Convert eventDate array to a Set for faster lookup
          const eventDateSet = new Set(event_dates);

          // Loop through fromtoDate to find dates not present in eventDate
          datesArray.forEach(date => {
              if (!eventDateSet.has(date)) {
                  notInEventDate.push(date);
              }
          }); 


          let i = 1001;
          notInEventDate.forEach((noeventDate, index) => {

            calendarEvents.push({
              id: i,
              title: "No event",
              start: moment(noeventDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD') + 'T' + '09:08:00',
              end: moment(noeventDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD') + 'T' + '09:08:00',
              noEvent: 1,
            });

            i++;
          });

          setEvent(calendarEvents);

        } else {
          
          setLoader(false);
          const calendarEvents = [];
           let i = 1001;
           datesArray.forEach((noeventDate, index) => {

            calendarEvents.push({
              id: i,
              title: "No event",
              start: moment(noeventDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD') + 'T' + '09:08:00',
              end: moment(noeventDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD') + 'T' + '09:08:00',
              noEvent: 1,
            });

            i++;
          });

          setEvent(calendarEvents);

        }


      })
        .catch(error => {
          setLoader(false);
          if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
            localStorage.clear();
            navigate("/login");
          }
        });

    }
    else {
      setLoader(false);
      navigate("/login");
    }
  }


  useEffect(() => {
    // This will run whenever fromDate or toDate changes
    if (CalfromDate && CaltoDate) {
      fetchData();
    }
  }, [CalfromDate, CaltoDate]);


  const calculateSessionDuration = (sesStartTime, sesEndTime) => {
    if (sesStartTime && sesEndTime) {
      const currentDate = "2024-02-04";
      const startTime = moment(sesStartTime, "h:mm A");
      const endTime = moment(sesEndTime, "h:mm A");
      
         
      
      const duration = moment.duration(endTime.diff(startTime)).asMinutes();
      return `${duration} mins`;
    } else {
      return "-";
    }

  };

  const getUniqueStudentSubjectNames = (jsonData) => {
    // Create a Set to store unique subjects
        const uniqueSubjects = new Set();
        if (
          jsonData
        ) {
        
          if (
            jsonData &&
            Array.isArray(jsonData)
          ) {

        // Iterate over each object in the jsonData array
        jsonData.forEach((subject) => {
          // Check if the subject object and its subject_name property are not null/undefined
          if (subject && subject.subject_name != null) {
            // Split the subject_name string by commas and trim each individual subject
            const subjectsArray = subject.subject_name.split(',').map(subject => subject.trim());
            // Add each subject to the uniqueSubjects set
            subjectsArray.forEach(subject => uniqueSubjects.add(subject));
          }
        });

        // Convert the uniqueSubjects set to an array
        const uniqueSubjectsArray = [...uniqueSubjects];
        

      }
    }
    // Convert Set to array if needed
    return Array.from(uniqueSubjects);
  };


  // Session Click move to session page  start
  const handelSession = (eventData) => {
    sessionStorage.setItem("session", JSON.stringify(eventData))
    navigate("/session/" + eventData.extendedProps.session);

  }
// Session Click move to session page end


  const EventWithDetails = (info) => {
    const event = info.event.extendedProps;
    if(event.noEvent && event.noEvent===1){
      return (
        <p className='calendar-empty'>No sessions scheduled</p>
      )
    }else{

    const student_data =[...(event.student || []), ...(event.reAssignStudent || [])] // Create a shallow copy of event.student object

    

    return (
      <div onClick={() => handelSession(info.event)}>
        <div className='cl-card'>
          <div className='sp-contents'>
            <div className="datetime d-flex flex-wrap justify-content-between px-05 mb-1 mt-1">
              <div className="time ">
                <small className='font-size-12'> {event.start_time} - {event.end_time}</small>
              </div>
              <div className="date ">
                <small className='font-size-12'>{event.start_time && event.end_time ? calculateSessionDuration(event.start_time, event.end_time) : null}</small>
              </div>
            </div>
            <div className='overflow-hidden px-05'>
              <h5>{info.event.title}</h5>
              {/* <div>{event.subject}</div> */}
            </div>

            <div className='info px-05'>
              <p className='mb-0 font-size-12'>{event.sest_is_holidays && event.sest_is_holidays == '1'? 'Recurring' : event.sest_is_holidays && event.sest_is_holidays == '2' ? 'One Off Session' : event.sest_is_holidays && event.sest_is_holidays == '3' ? 'Make Up Session' : null } <span><i class="fa fa-circle font-size-10"> </i></span> {getUniqueStudentSubjectNames(student_data).join('& ') || 'No subject'}</p>
            </div>

            <div className='phase-divider'></div>

            {event.student.length > 0 || event.reAssignStudent.length > 0 ? (
              <>
                {event.student.map((studentData, index) => (
                  <React.Fragment key={index}>
                    <div className='d-flex flex-wrap mb-0 px-05'>
                      <div>
                        <img
                          src={event.avatar}
                          alt="Avatar"
                          style={{ width: '15px', height: '15px', borderRadius: '50%', marginRight: '5px' }}
                        />
                      </div>
                      <div className='px-05'>
                        <p className='font-size-12'>{studentData.st_first_name ? studentData.st_first_name + ' ' + studentData.st_surname : null}</p>
                      </div>
                    </div>
                    <div className='custom-lft mb-1 px-05'>
                      <small>{studentData.subject_name ? studentData.subject_name : null}</small>
                    </div>
                    <div className='custom-lft mb-3 px-05'>
                      <small className="text-danger">{studentData.activity_count <= 0 ? 'No activities assigned' : null}</small>
                    </div>
                  </React.Fragment>
                ))}

                {event.reAssignStudent.map((studentData, index) => (
                  <React.Fragment key={index}>
                    <div className=' d-flex flex-wrap mb-0'>
                      <div>
                        <img
                          src={event.avatar}
                          alt="Avatar"
                          style={{ width: '18px', height: '18px', borderRadius: '50%', marginRight: '5px' }}
                        />
                      </div>
                      <div>
                        <strong>* {studentData.st_first_name ? studentData.st_first_name + ' ' + studentData.st_surname : null}</strong>
                      </div>
                    </div>
                    <div className='custom-lft mb-1'>
                      <small>{studentData.subject_name ? studentData.subject_name : null}</small>
                    </div>
                    <div className='custom-lft mb-3 px-05'>
                      <small className="text-danger">{studentData.activity_count <= 0 ? 'No activities assigned' : null}</small>
                    </div>
                  </React.Fragment>
                ))}
              </>
            ) : (
              <p className=''>No student details</p>
            )}
          </div>
          {/* 
          <div className='st-upcomming'>

          </div> */}

          <div className=''>
            {parseInt(event.status) === 0 ? <div className='cs-status st-upcoming'>Upcoming </div> : parseInt(event.status) === 1 && parseInt(event.session_cmpleted) === 0 ? <div className='cs-status st-ongoing'>Ongoing </div> : parseInt(event.status) === 1 && parseInt(event.session_cmpleted) === 1 ? <div className='cs-status st-completed'>Completed</div> : <div className='cs-status st-ongoing'>Ongoing</div>}
          </div>
        </div>
      </div>
    );
    }
  };


  useEffect(() => {
    if (calendarRef.current && selectedDate) {
     
      const calendarApi = calendarRef.current.getApi();
     
     //calendarApi.gotoDate(moment(selectedDate,'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('YYYY-MM-DD'));
     calendarApi.gotoDate(moment.utc(selectedDate).format('YYYY-MM-DD'));
    // calendarApi.changeView('dayGridWeek', {
    //   start: '2017-06-01',
    //   end: '2017-06-05'
    // });
    // calendarApi.changeView('dayGridWeek', moment(selectedDate,'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('YYYY-MM-DD'))

    // const calendarApi = calendarRef.current.getApi();
    //setFirstDayOption(0);

// calendarApi.setOption('visibleRange', {
//   start: '2017-04-01',
//   end: '2017-04-05'
// });
    }
  }, [selectedTempDate]);

  useEffect(() => {
    if (calendarRef.current && dateValue && dateValue[0]!=null && dateValue[0]!='') {

      const calendarApi = calendarRef.current.getApi();
    
   //calendarApi.gotoDate(moment(dateValue[0],'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('YYYY-MM-DD'));
   calendarApi.gotoDate(moment.utc(dateValue[0]).format('YYYY-MM-DD'));
   
    // calendarApi.changeView('dayGridWeek', {
    //   start: '2017-06-01',
    //   end: '2017-06-05'
    // });
    // calendarApi.changeView('dayGridWeek', moment(selectedDate,'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('YYYY-MM-DD'))
    //const date_data = moment(dateValue[0],'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
    const date_data = moment.utc(dateValue[0]);

    
   const day_data = date_data.format('dddd');
    // Get the day of the week as a number (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    var dayOfWeekNumber = date_data.day();
   
    // Check the day of the week using if-else statements
    if (dayOfWeekNumber === 0) {
       
        setFirstDayOption(0);
    } else if (dayOfWeekNumber === 1) {
      setFirstDayOption(1);
    } else if (dayOfWeekNumber === 2) {
      setFirstDayOption(2);
    } else if (dayOfWeekNumber === 3) {
      setFirstDayOption(3);
    } else if (dayOfWeekNumber === 4) {
      setFirstDayOption(4);
    } else if (dayOfWeekNumber === 5) {
      setFirstDayOption(5);
    } else if (dayOfWeekNumber === 6) {
      setFirstDayOption(6);
    } else {
        
        setFirstDayOption(0);
    }
    

    }
  }, [TempdateValue]);

  

  const handleDatePickerChange = date => {
   // const select_date = moment(date,'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('YYYY-MM-DD');
   const select_date = date;
   
    setSelectedDate(select_date);
    setSelectedTempDate(select_date)
  };

  return (
    <React.Fragment>
      <Loader
        show={loader}
        type="body"
      // stack="vertical"
      // message="Loading Data"
      />
      <div className="page-content">
        <Container fluid>

        <Row className="cust-rg-gutter mb-4">
            <Col xl={4}  lg={4} md={6} sm={6} xs={12}>
              <Card
                className="mini-stat"
                style={{ background: "rgb(242, 198, 222)" }}
              >
                <CardBody>
                  <div className="d-flex mb-3 flex-wrap">
                    <div className=" ">
                      <img width={30} src={es1} alt="" />
                    </div>
                    <div>
                      <h5 className="mt-0 mb-2 text-dark font-size-17">
                        Sessions taught this year
                      </h5>
                      <h4 className="fw-bold mb-0 text-dark">{YearSession && YearSession!==null && YearSession!=='' ? YearSession : 0 } </h4>
                    </div>
                  </div>
                  <div className="pt-4 mb-1">
                    <div className="ft-session">
                      <Link to="/session" className="">
                        <p className="mb-0 mt-1 mb-2 font-size-14 text-dark fw-medium">
                          {/* +{WeekSession} this week */}
                          View all sessions this week
                        </p>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl={3} lg={3} md={6} sm={6} xs={12}> 
              <Card
                className="mini-stat"
                style={{ background: "rgb(247, 217, 202)" }}
              >
                <CardBody>
                  <div className="d-flex mb-3 flex-wrap">
                    <div className="">
                      <img width={30} src={es2} alt="" />
                    </div>
                    <div>
                      <h5 className="mt-0 mb-2 text-dark font-size-17">
                        Pending Payments
                      </h5>
                      <h4 className="fw-bold mb-0 text-dark">
                        {currencyType} {PendingAmount && PendingAmount!==null && PendingAmount!=='' ? PendingAmount : 0 }{" "}
                      </h4>
                    </div>
                  </div>
                  <div className="pt-4 mb-1">
                    <div className="ft-session">
                      <Link to="/timesheet" className="">
                        <p className="mb-0 mt-1 mb-2 font-size-14 text-dark fw-medium">
                          View all payments
                        </p>
                      </Link>
                    </div>
                    
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <Card
                className="mini-stat"
                style={{ background: "rgb(198, 222, 241)" }}
              >
                <CardBody>
                  <div className="d-flex mb-3 flex-wrap">
                    <div className="">
                      <img width={30} src={es3} alt="" />
                    </div>
                    <div>
                      <h5 className="mt-0 mb-2 text-dark font-size-17">
                        Unplanned lessons (next 7 days)
                      </h5>
                      <h4 className="fw-bold mb-0 text-dark">
                        {UnplannedLesson || 0}{" "}
                      </h4>
                    </div>
                  </div>
                  <div className="pt-4 mb-1">
                    <div className="ft-session">
                      <Link to={{
                        pathname: '/students',
                        state: { from: 'pase' } 
                      }} onClick={() => sessionStorage.setItem("UnplanedLesson", true) } className="">
                        <p className="mb-0 mt-1 mb-2 font-size-14 text-dark fw-medium">
                          View upcoming lessons planned.
                        </p>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <Card
                className="mini-stat"
                style={{ background: "rgb(219, 205, 240)" }}
              >
                <CardBody>
                  <div className="d-flex mb-3 flex-wrap">
                    <div className="">
                      <img width={30} src={es4} alt="" />
                    </div>
                    <div>
                      <h5 className="mt-0 mb-2 text-dark font-size-17">
                        Marking to be completed
                      </h5>
                      <h4 className="fw-bold mb-0 text-dark">
                      {OutStandingMark && OutStandingMark!=null && OutStandingMark!='' ? OutStandingMark : 0 }{" "}
                      </h4>
                    </div>
                  </div>
                  <div className="pt-4">
                    
                    <Link to={{
                        pathname: '/student-details',
                        state: { from: 'pase' } 
                      }}  className="">
                        <div className="cs-mark">
                        Mark
                        </div>
                      </Link>
                      
                      
                    {/* <p className="mb-0 mt-1">+2 This Week</p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        

          <div className='calendar-section position-relative'>
            {/* <div className='custom-dp'> */}
           { !IsWeekView ? <DatePicker
           format="dd-MM-yyyy"
             // selected={moment(selectedDate, 'YYYY-MM-DD').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)')}
             value={selectedDate}
              onChange={handleDatePickerChange}
              cleanable = {false}
              className='datepickerHome'
              //format="yyyy-MM-dd"
            /> :

          <DateRangePicker shouldDisableDate={allowedDays(7)}  value={dateValue} format="dd-MM-yyyy"
              onChange={handleDateChange} showOneCalendar cleanable = {false} ranges={[]}
              className='datepickerHome' />

           }
            {/* <img width={24} src={dp} alt="" />
           
            </div> */}
            <FullCalendar
              height={600}
              plugins={[
                BootstrapTheme,
                dayGridPlugin,
                interactionPlugin,
                listPlugin
              ]}
            
             // titleFormat= { {  day: '2-digit' }}
           dayHeaderFormat={{ day: 'numeric' , weekday: 'short',   omitCommas: true }}
            //  dayHeaderFormat={({ date }) => {
            //   const day = date.toLocaleString('en', { day: 'numeric' });
            //   const weekday = date.toLocaleString('en', { weekday: 'short' });
            //   return (
            //     <div>
            //       <span>{day}</span>
            //       <br />
            //       <span>{weekday}</span>
            //     </div>
            //   );
            // }}
            // dayHeaderContent={(args) => {
            //   return moment(args.date).zone('+08:00').format('DD ddd');
            // }}
              ref={calendarRef}
              // views = {{
              //   dayGridWeek: {
                  
              //       duration: { days: 7 },
              //   }
              // }}
              firstDay= {firstDayOption}
              columnHeaderFormat={({ date }) => {
                const day = date.getDate();
                const weekday = date.toLocaleString('en', { weekday: 'short' });
                return `${day} ${weekday}`;
              }}
              initialView={IsWeekView ?  "dayGridWeek" : "dayGridDay" }
              initialDate={localStorage.getItem("CalenderFromDate") || null}
              handleWindowResize={true}
              themeSystem="bootstrap"
              headerToolbar={{
                left: "prev,title,next",
                right: "dayGridDay,dayGridWeek",
              }}
             
              events={events}
              datesSet={handleDatesSet}
              selectable={true}
              eventContent={EventWithDetails}
          
            />
          </div>

        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Dashboard);
