import React, { useEffect, useState, useMemo } from "react"

import {
  Container,
  Card,
  CardBody,
  Button,
  Label,
  Row,
  Col,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import paginationFactory from "react-bootstrap-table2-paginator"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
import TableComm from "../../dataTableCommon"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { DateRangePicker, Stack } from "rsuite"
import Select from "react-select"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import moment from "moment"
import { Link, useNavigate } from "react-router-dom"
// import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "react-spinner-loader"
import { SECURITY_KEY, BASE_URL } from "../../global";
const ServerSideTable = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState([null, null])
  const [subject, setSubject] = useState("")
  const [type, setType] = useState("")
  const [status, setStatus] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [records, setRecords] = useState(data)
  //Kathir start
  const today = new Date()
  const nextWeek = new Date()
  nextWeek.setDate(today.getDate() + 6)
  const [dateValue, setDateValue] = useState([today, nextWeek])
  const [optionGroup, setoptionGroup] = useState([])

  const [selectedGroup, setselectedGroup] = useState("")

  function handleSelectGroup(selectedGroup) {
    console.log(selectedGroup)
    setselectedGroup(selectedGroup)
  }

  const [selectedType, setselectedType] = useState("")

  function handleSelectType(selectedType) {
    console.log(selectedType)
    setselectedType(selectedType)
  }

  const [selectedStatus, setselectedStatus] = useState("")

  function handleSelectStatus(selectedStatus) {
    console.log(selectedStatus)
    setselectedStatus(selectedStatus)
  }

  const handleDateChange = value => {
    // Do something with the selected date range
    setDateValue(value)
  }

  const optionType = [
    { label: "Select", value: "" },
    { label: "Recurring", value: "1" },
    { label: "One Off Session", value: "2" },
    { label: "Make Up Session", value: "3" },
  ]

  const optionStatus = [
    { label: "Select", value: "" },
    { label: "Upcoming", value: "1" },
    { label: "Ongoing", value: "2" },
    { label: "Completed", value: "3" },
  ]
  //Kathir end

  // const [selectedGroup, setselectedGroup] = useState(null);

  // function handleSelectGroup(selectedGroup) {
  //   setselectedGroup(selectedGroup);
  // }

  // const optionGroup = [
  //   {
  //     label: "Select Subject",
  //     options: [
  //       { label: "All", value: "All" },
  //       { label: "English", value: "English" },
  //       { label: "Maths", value: "Maths" },
  //       { label: "English & Maths", value: "English & Maths" }
  //     ]
  //   },

  // ];
  const navigate = useNavigate()
  //Kathir end
  const authUser = JSON.parse(localStorage.getItem("authUser")) || null

  const fetchData = async () => {
    try {
      if (authUser && authUser != "" && authUser != null) {
       // console.log(dateValue);
        setLoading(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        //const response = await axios.get('/api/data'); // Replace with your API endpoint
        const response = await axios.post(
          BASE_URL+"session/getAllSessionList",
          {
            draw: 1,
            start: 0,
            length: rowsPerPage,
            order: [{ column: 0, dir: "asc" }],
            columns: [
              { data: "session_date" },
              { data: "session_time" },
              { data: "sest_name" },
              { data: "total_student" },
              { data: "subject" },
              { data: "sest_type" },
              { data: "ses_is_completed" },
            ],
            search: { value: "" },
            // startDate:
            //   moment(dateValue[0], "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
            //     "YYYY-MM-DD"
            //   ) || null,
            // endDate:
            //   moment(dateValue[1], "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
            //     "YYYY-MM-DD"
            //   ) || null,
// Parse dates using Moment.js
              startDate: moment.utc(dateValue[0]).format('YYYY-MM-DD'),
              endDate: moment.utc(dateValue[1]).format('YYYY-MM-DD'),

           
            

            subject: selectedGroup,
            filtertype: selectedType,
            filterstatus: selectedStatus,
            type: type,
            status: status,
          }
        )

        if (response.data.length > 0) {
          setData(response.data)
          setRecords(response.data)
          setLoading(false)
        } else {
          setData([])
          setRecords([])
          setLoading(false)
        }
      } else {
        setLoading(false)
        localStorage.clear()
        navigate("/login")
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error)
      if (
        error.response.status &&
        error.response.status === 403 &&
        error.response.data.error === "jwt expired"
      ) {
        localStorage.clear()
        navigate("/login")
      }
      
    }
  }
  const fetchMasterData = async () => {
    try {
      if (authUser && authUser != "" && authUser != null) {
        // setLoading(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsSubject = await axios.post(
          BASE_URL+"session/centreSubject"
        )
        //console.log(response)
        if (responsSubject.data.length > 0) {
          const formattedSubjectOptions = responsSubject.data.map(option => ({
            value: option.subject_id,
            label: option.subject_name,
          }))
          formattedSubjectOptions.unshift({
            value: "",
            label: "Select",
          })
          setoptionGroup(formattedSubjectOptions)
        } else {
          setoptionGroup([])
        }
      } else {
        navigate("/login")
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      if (
        error.response.status &&
        error.response.status === 403 &&
        error.response.data.error === "jwt expired"
      ) {
        localStorage.clear()
        navigate("/login")
      }
    }
  }
  useEffect(() => {
    fetchMasterData()
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [dateValue, selectedGroup, selectedStatus, selectedType])

  const filterReset = () => {
    handleSelectGroup("")
    handleSelectStatus("")
    handleSelectType("")
    const today = new Date()
    const nextWeek = new Date()
    nextWeek.setDate(today.getDate() + 6)
    setDateValue([today, nextWeek])
    // setChecked(false);
    // setFilter(!isFilter);
  }

  const columns = useMemo(
    () => [
      {
        name: "Date",
        Header: "Date",
        accessor: row =>
          row.ses_date != null || row.ses_date != ""
            ? moment(row.ses_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                "DD MMM YYYY (ddd)"
              )
            : "-",
        // disableFilters: true,
        // disableSortBy: true,
      },
      {
        name: "Time",
        Header: "Time",
        accessor: row =>
          row.ses_start_time != null || row.ses_start_time != ""
            ? moment(row.ses_start_time, "HH:mm:ss").format("hh:mm A") +
              " - " +
              moment(row.ses_end_time, "HH:mm:ss").format("hh:mm A")
            : "-",
      },
      {
        name: "Session Name",
        Header: "Session Name",
        accessor: row =>
          row.sest_name != null || row.sest_name != "" ? row.sest_name : "-",
      },
      {
        name: "Student",
        Header: "Student",
        accessor: row =>
          row.student_enrol_count == null || row.student_enrol_count == ""
            ? "-"
            : row.student_enrol_count,
      },
      {
        name: "Subject",
        Header: "Subject",
        accessor: row =>
          row.student_subject == null || row.student_subject == ""
            ? "-"
            : row.student_subject,
      },
      {
        name: "Type",
        Header: "Type",
        accessor: row =>
          row.sest_is_holidays == null || row.sest_is_holidays == ""
            ? "-"
            : row.sest_is_holidays == "1"
            ? "Recurring"
            : row.sest_is_holidays == "2"
            ? "One Off Session"
            : row.sest_is_holidays == "3"
            ? "Make Up Session"
            : "-",
      },
      {
        Header: "Status",
        disableSortBy: true,
        accessor: "ses_status",
        disableFilters: true,
        Cell: ({ row }) => {
          const rowValue = row.original
          console.log(rowValue.ses_status)
          // Conditionally render content based on the value of tp_status
          if (
            parseInt(rowValue.ses_status) === 0 ||
            rowValue.ses_status === null
          ) {
            return (
              <button className="btn tb-primary text-white text-uppercase tb-bdge">
                Upcoming
              </button>
            )
          } else if (
            parseInt(rowValue.ses_status) === 1 &&
            parseInt(rowValue.ses_is_completed) === 1
          ) {
            return (
              <button className="btn text-uppercase tb-bdge tb-success">
                Completed
              </button>
            )
          } else if (
            parseInt(rowValue.ses_status) === 1 &&
            parseInt(rowValue.ses_is_completed) === 0
          ) {
            return (
              <button className="btn tb-warning text-white text-uppercase tb-bdge">
                {" "}
                On Going
              </button>
            )
          } else {
            return (
              <button className="btn tb-primary text-white text-uppercase tb-bdge">
                Upcoming
              </button>
            )
          }
        },
      },
      {
        Header: "",
        disableSortBy: true,
        accessor: "pk_ses_key",
        disableFilters: true,
        Cell: ({ row }) => {
          const rowValue = row.values
          // Conditionally render content based on the value of tp_status
          if (rowValue.pk_ses_key && rowValue.pk_ses_key != null) {
            return (
              <Link className="p-0" to={"/session/" + rowValue.pk_ses_key}>
                <i
                  class="mdi mdi-chevron-right-circle-outline"
                  style={{ color: "#979797", fontSize: "20px" }}
                ></i>
              </Link>
            )
          } else {
            return ""
          }
        },
      },
      // {
      //   accessor: 'student_key', disableSortBy: true, disableFilters: true,           isVisible: false,

      //   selector: (row) => row.values.pk_student_key, show: false,
      // },
      // {
      //   accessor: 'visibility', disableSortBy: true, disableFilters: true,     isVisible: false,

      //   selector: (row) => row.visibility,

      // }
    ],
    []
  )

  const handleStudentViewClick = async e => {
    console.log(e)
    // if (parseInt(e.values.visibility) == 1) {
    //   navigate(`/view-student`, { state: { pk_student_key: `${e.values.pk_student_key}` } });
    // } else {

    //   toast.error("Sorry! Allow Only active Student.", {
    //     autoClose: 3000, // 3 seconds in this example
    //   });

    // }
  }

  function handleFilter(event) {
    const inputValue = event.target.value.trim().toLowerCase()
    const newData = data.filter(row => {
      return (
        (row.ses_date != null || row.ses_date != ""
          ? moment(row.ses_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
              "DD-MM-YYYY"
            )
          : "-"
        ).includes(inputValue) ||
        (row.ses_start_time != null || row.ses_start_time != ""
          ? moment(row.ses_start_time, "HH:mm:ss").format("hh:mm A") +
            " " +
            moment(row.ses_end_time, "HH:mm:ss").format("hh:mm A")
          : "-"
        ).includes(inputValue) ||
        (row.sest_name != null || row.sest_name != "" ? row.sest_name : "-")
          .toLowerCase()
          .includes(inputValue) ||
        (row.sest_is_holidays == null || row.sest_is_holidays == ""
          ? "-"
          : row.sest_is_holidays == "1"
          ? "Recurring"
          : row.sest_is_holidays == "2"
          ? "One Off Session"
          : row.sest_is_holidays == "3"
          ? "Make Up Session"
          : "-"
        )
          .toLowerCase()
          .includes(inputValue) ||
        (row.student_subject == null || row.student_subject == ""
          ? "-"
          : row.student_subject
        )
          .toLowerCase()
          .includes(inputValue) ||
        (parseInt(row.ses_status) === 0 || row.ses_status === null
          ? "Upcoming"
          : row.ses_status &&
            row.ses_status != null &&
            row.st_is_completed &&
            row.st_is_completed != null &&
            parseInt(row.ses_status) === 1 &&
            parseInt(row.st_is_completed) === 1
          ? "Completed"
          : parseInt(row.ses_status) === 1 &&
            parseInt(row.st_is_completed) === 0
          ? "Ongoing"
          : "Upcoming"
        )
          .toLowerCase()
          .includes(inputValue)
      )
    })
    setRecords(newData)
  }

  return (
    <div className="page-content">
      <Loader
        show={loading}
        type="body"
      // stack="vertical"
      // message="Loading Data"
      />
      <Container fluid>
        <form className="app-search page-search">
          <div className="position-relative">
            <span className="ion ion-ios-search"></span>
            <input
              type="text"
              className="form-control"
              placeholder={"Search for session name..."}
              onChange={handleFilter}
            />
          </div>
        </form>

        <div className="filter-section d-flex mb-4 flex-wrap">
          <div className="me-12">
            <label>Date</label>
            <br></br>
            <DateRangePicker
              format="dd-MM-yyyy"
              value={dateValue}
              onChange={handleDateChange}
              cleanable={false}
            />
          </div>

          <div className="filter-child me-12">
            <label>Subject </label>
            <Select
              className="cs-style"
              value={optionGroup.find(option => option.value === selectedGroup)}
              onChange={selectedOption => {
                handleSelectGroup(selectedOption["value"])
              }}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </div>

          <div className="filter-child me-12">
            <label>Type</label>
            <Select
              className="cs-style"
              value={optionType.find(option => option.value === selectedType)}
              onChange={selectedOption => {
                handleSelectType(selectedOption["value"])
              }}
              options={optionType}
              classNamePrefix="select2-selection"
            />
            {/* <Select className='cs-style'
                  value={selectedGroup}
                  onChange={() => {
                    handleSelectGroup();
                  }}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                /> */}
          </div>
          <div className="filter-child me-12">
            <label>Status</label>
            <Select
              className="cs-style"
              value={optionStatus.find(
                option => option.value === selectedStatus
              )}
              onChange={selectedOption => {
                handleSelectStatus(selectedOption["value"])
              }}
              options={optionStatus}
              classNamePrefix="select2-selection"
            />
            {/* <Select className='cs-style'
                    value={selectedGroup}
                    onChange={() => {
                      handleSelectGroup();
                    }}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                  /> */}
          </div>
          <div className="me-1">
            <Button
              className="filter-btn mt-4"
              color="primary"
              onClick={filterReset}
            >
              Clear filter
            </Button>
          </div>
        </div>

        <div className="table-responsive">
       
          <TableComm
            loading={loading}
            columns={columns}
            defaultId={"sest_name"}
            data={records}
            // onRowClick={handleStudentViewClick}  hiddenColumns={['visibility', 'student_key']}
          />
           
        </div>
      </Container>
    </div>
  )
}

export default ServerSideTable
