import PropTypes from "prop-types"
import React, { useState, useCallback, useRef, useMemo, useEffect } from "react"

import {
  Container,
  Table,
  Card,
  CardBody,
  Button,
  Label,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  TabContent,
  TabPane,
  Progress,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import { Link, useNavigate, useLocation, useParams } from "react-router-dom"
import Select from "react-select"
import moment from "moment"

import { Pie } from "react-chartjs-2"
import { Line } from "react-chartjs-2"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import "chartist/dist/scss/chartist.scss"
import TableComm from "../../dataTableCommon"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import MUIDataTable from "mui-datatables"
// import Grid from "@mui/material/Grid"
// // import { DataGrid } from '@mui/x-data-grid';
// import LinearProgress from "@mui/material/LinearProgress"
// import Box from "@mui/material/Box"
// import {
//   GridRowModes,
//   DataGrid,
//   GridToolbar,
//   GridRowParams,
//   GridToolbarContainer,
//   GridActionsCellItem,
//   GridRowEditStopReasons,
// } from "@mui/x-data-grid"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import Loader from "react-spinner-loader"
//datatable
import { SECURITY_KEY, BASE_URL } from "../../global";

const StudentList = props => {
  //datatable
  const { key } = useParams()
  const location = useLocation();
  useEffect(() => {
      
        if (key && key!='') {
        }else{
          toast.error("Sorry, Key missing!", {
            autoClose: 3000, // 3 seconds in this example
            onClose: () => {window.history.back()},
          });
        }
    }, [location]);
  const [selectedGroup, setselectedGroup] = useState("3")
  const [loading, setLoading] = useState(true)
  function handleSelectGroup(selectedGroup) {
    console.log(selectedGroup)
    setselectedGroup(selectedGroup)
  }

 
  const toggleRightCanvas = studentKey => {
   
  }


  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }

  // const [date, setDate] = useState(new Date());
  const [date, setDate] = useState([null, null])

  const [subject, setSubject] = useState("")
  const [type, setType] = useState("")
  const [status, setStatus] = useState("")
  //const [Alldata, setAllData] = useState([]);
  const [data, setData] = useState([])
  const [records, setRecords] = useState(data)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [startDate, setStartDate] = useState("")
  const handelDateReset = () => {
    setStartDate("")
  }

 
  //Kathir start
  const optionGroup = [
    { label: "All", value: "3" },
    { label: "Completed", value: "1" },
    { label: "Incomplete", value: "2" },
  ]
  //const [optionGroup, setoptionGroup] = useState([])
  const [isFilter, setFilter] = useState(false)
  const [isChecked, setChecked] = useState(true)

 
  const handleCheckboxChange = () => {
    setChecked(!isChecked) // Toggle the checkbox state
  }

  const navigate = useNavigate()
  //Kathir end
  const authUser = JSON.parse(localStorage.getItem("authUser")) || null
  

  const fetchData = async () => {
    try {
     
      if (authUser && authUser != "" && authUser != null) {
       
        if (sessionStorage.getItem("UnplanedLesson") && sessionStorage.getItem("UnplanedLesson") == 'true') {
         setChecked(true);
         sessionStorage.removeItem("UnplanedLesson");
       } 
        setLoading(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const response = await axios.post(
          BASE_URL+"session/getSessionsActivityManualDetails",
          {
            draw: 1,
            start: 0,
            length: rowsPerPage,
            order: [{ column: 0, dir: "asc" }],
            columns: [
              { data: "session_date" },
              { data: "session_time" },
              { data: "sest_name" },
              { data: "total_student" },
              { data: "subject" },
              { data: "sest_type" },
              { data: "ses_is_completed" },
            ],
            search: { value: "" },
            startDate: date[0] || null,
            endDate: date[1] || null,
            pk_student_key: key,
            subject: subject,
            type: type,
            status: status,

            // filterStartDate: startDate,
            //filterunplannedLesson: isChecked,
            filterStatus: selectedGroup,
          }
        )
        //console.log(response)
        if (response.data.length > 0) {

          setData(response.data)
          setRecords(response.data)

          setLoading(false)
        } else {
          setData([])
          setRecords([])
          // console.log(dataTableData)
          setLoading(false)
        }
      } else {
        navigate("/login")
        setLoading(false)
      }

      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
      if (
        error.response.status &&
        error.response.status === 403 &&
        error.response.data.error === "jwt expired"
      ) {
        localStorage.clear()
        navigate("/login")
      }
      setLoading(false)
    }
  }



  const filterApply = () => {
    toggleRightCanvas()
    setFilter(!isFilter)
  }

  const filterReset = () => {
    handleSelectGroup("")
    setChecked(false)
    setFilter(!isFilter)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [selectedGroup])
  //datable

  // useEffect(() => {
  //   fetchData()
  // }, [])

  // const products = [
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     level: "Intermediate",
  //     school: "ABC School",
  //     sessions: 10,
  //     attended: 7,
  //     nextsession: "2024-02-10",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     level: "Advanced",
  //     school: "XYZ School",
  //     sessions: 15,
  //     attended: 12,
  //     nextsession: "2024-02-12",
  //   },
  //   {
  //     id: 3,
  //     name: "Michael Johnson",
  //     level: "Beginner",
  //     school: "123 School",
  //     sessions: 8,
  //     attended: 6,
  //     nextsession: "2024-02-08",
  //   },
  //   {
  //     id: 4,
  //     name: "Emily Brown",
  //     level: "Intermediate",
  //     school: "456 School",
  //     sessions: 12,
  //     attended: 10,
  //     nextsession: "2024-02-11",
  //   },
  //   // Add more product data as needed
  // ]

  const columns = useMemo(
    () => [
      {
        name: "Student Name",
        Header: "Student Name",
        // accessor: 'st_first_name',
        accessor: row =>
          row.st_first_name && row.st_first_name != ""
            ? row.st_first_name + " " + row.st_surname
            : "-",
      },
      {
        name: " Session Name",
        Header: " Session Name",
        accessor: row =>
          row.sest_name != null && row.sest_name != ""
            ? row.sest_name
            : "-",
      },
       {
        name: "Session Date",
        Header: "Session Date",
        accessor: row =>
          row.ses_date != null && row.ses_date != ""
            ? moment(row.ses_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                "DD-MM-YYYY"
              )
            : "-",
      },
      {
        name: "Activity",
        Header: "Activity",
        accessor: row =>
          parseInt(row.marked_for_homework) === 1 ? 'HomeWork' : 'Lesson',
           
      },
      {
        name: "Activity Name",
        Header: "Activity Name",
        accessor: row =>
          row.activity_node != null && row.activity_node != ""
            ? row.activity_node
            : "-",
      },
      {
        Header: "Status",
        disableSortBy: true,
        accessor: "status",
        disableFilters: true,
        Cell: ({ row }) => {
          const rowValue = row.original
          console.log(rowValue.status)
          // Conditionally render content based on the value of tp_status
          if (parseInt(rowValue.status) === 1) {
            return (
              <button className="btn tb-success tb-bdge">Completed</button>
            )
          } else {
            return (
              <button className="btn tb-primary text-white tb-bdge">
                Incomplete
              </button>
            )
          }
        },
      },
      {
        Header: "#",
        disableSortBy: true,
        accessor: "pk_lesson_activity_question_id",
        disableFilters: true,
     

        Cell: ({ row }) => (
          <>
            <button
              className="btn p-0 hgt"
              onClick={() => onManualMarkingActivity(row.original)}
            >
              <i
                class="mdi mdi-chevron-right-circle-outline"
                style={{ color: "#979797", fontSize: "24px" }}
              ></i>
            </button>
          </>
        ),
      },
      {
        accessor: "student_key",
        disableSortBy: true,
        disableFilters: true,
        isVisible: false,

        selector: row => row.values.pk_student_key,
        show: false,
      },
    ],
    []
  )

 
  // const paginationOptions = {
  //   paginationSize: 5,
  //   pageStartIndex: 1,
  //   firstPageText: "First",
  //   prePageText: "Back",
  //   nextPageText: "Next",
  //   lastPageText: "Last",
  //   nextPageTitle: "First page",
  //   prePageTitle: "Pre page",
  //   firstPageTitle: "Next page",
  //   lastPageTitle: "Last page",
  // }

  //tab

  // const [activeTab, setActiveTab] = useState("1");

  // const toggleTab = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };


  function onManualMarkingActivity(activityDetails) {
    if(activityDetails && parseInt(activityDetails.status) === 1){
    if(activityDetails && parseInt(activityDetails.activity_type) === 0){
      if(activityDetails.pk_lesson_activity_key && activityDetails.fk_sub_id && activityDetails.fk_student_key){
        navigate(`/manual-assessment/${activityDetails.pk_lesson_activity_key}`, { state: { type: `${activityDetails.fk_sub_id}`, st_key: `${activityDetails.fk_student_key}`} });
      }else{
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
      
    }else if(activityDetails && parseInt(activityDetails.activity_type) === 5){
      if(activityDetails.pk_lesson_activity_key && activityDetails.fk_sub_id && activityDetails.fk_student_key){
        navigate(`/manual-worksheet/${activityDetails.pk_lesson_activity_key}`, { state: { type: `${activityDetails.fk_sub_id}`, st_key: `${activityDetails.fk_student_key}`} });
      }else{
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    }else if(activityDetails && parseInt(activityDetails.activity_type) === 7){
      if(activityDetails.pk_lesson_activity_key && activityDetails.fk_sub_id && activityDetails.fk_student_key){
        navigate(`/manual-lcms-worksheet/${activityDetails.pk_lesson_activity_key}`, { state: { type: `${activityDetails.fk_sub_id}`, st_key: `${activityDetails.fk_student_key}`} });
      }else{
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }else{
    toast.error("Sorry, Activity Not Completed", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  }

  function handleFilter(event) {
    const inputValue = event.target.value.trim().toLowerCase()
    const newData = data.filter(row => {
      return (
        
        (row.st_first_name && row.st_first_name != ""? row.st_first_name + " " + row.st_surname : "-").toLowerCase().includes(inputValue) ||
        (row.sest_name != null && row.sest_name != ""? row.sest_name : "-").toLowerCase().includes(inputValue) ||
        (row.ses_date != null && row.ses_date != ""? moment(row.ses_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD-MM-YYYY") : "-").includes(inputValue) ||
        (parseInt(row.marked_for_homework) === 1 ? 'HomeWork' : 'Lesson').toLowerCase().includes(inputValue) ||
        (row.activity_node != null && row.activity_node != "" ? row.activity_node : "-").toLowerCase().includes(inputValue) ||
        (parseInt(row.status) === 1 ? 'Completed' : 'Incomplete').toLowerCase().includes(inputValue) 
      )
    })
    setRecords(newData)
  }

  const [activeTab1, setActiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setActiveTab1(tab)
    }
  }

  //modal
  const [modal_center, setmodal_center] = useState(false)
  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const [modal_center1, setmodal_center1] = useState(false)
  const tog_center1 = () => {
    setmodal_center1(!modal_center1)
    removeBodyCss()
  }

  // Function to handle date change
  const handleDateChange = date => {
    setStartDate(date)
    // Here you can implement logic to fetch data for the selected month
    // and update the chart accordingly
  }

  // // Function to generate labels and data for the last six months
  // const generateLastSixMonthsData = () => {
  //   const labels = [];
  //   const dataPoints = [];
  //   let currentDate = new Date();
  //   for (let i = 0; i < 6; i++) {

  //     console.log(currentDate.getMonth());
  //     console.log(currentDate.getMonth());
  //     labels.unshift(currentDate.toLocaleString('default', { month: 'long' }));
  //     // Replace this with actual data-fetching logic based on the current month
  //     dataPoints.unshift(Math.floor(Math.random() * 100)); // Sample random data
  //     currentDate.setMonth(currentDate.getMonth() - 1);
  //   }
  //   return { labels, dataPoints };
  // };

  // const { labels, dataPoints } = generateLastSixMonthsData();

  // console.log(labels);

  // console.log(TopicGraphDeatils.LabelData);


  

  return (
    <div className="page-content">
      <Loader
        show={loading}
        type="body"
      // stack="vertical"
      // message="Loading Data"
      />
      <ToastContainer autoClose={3000} />
      <Container fluid>
        <div className="page-title-box">
          <div className="custom-pg_header">
            <div className="d-flex gap-2 align-items-center flex-wrap">
            <div className="d-flex align-items-center gap-2">
                  <Button
                    color="light"
                    className="bck-btn"
                    onClick={() => window.history.back()}
                  >
                    <i className="ion ion-ios-arrow-back"></i>
                  </Button>
              </div>
              <form className="app-search page-search">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for student name..."
                    onChange={handleFilter}
                  />
                  <span className="ion ion-ios-search"></span>
                </div>
              </form>

              
            </div>
          </div>
        </div>
        <div className="d-flex mt-3 mb-3">
          <div className="filter-child me-3">
            <label>Status</label>
            <Select
              className="stud_level"
              value={optionGroup.find(option => option.value == selectedGroup)}
              onChange={selectedOption => {
                handleSelectGroup(selectedOption["value"])
              }}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </div>

          {/* <div className="form-check mt-4 ms-3 d-flex">
            <input
              type="checkbox"
              className="form-check-input check_box"
              id="unplannedLesson"
              checked={isChecked} // Control the checkbox state
              onChange={handleCheckboxChange}
            />

            <label className="form-check-label mb-0" htmlFor="unplannedLesson">
              Unplanned lessons (next 7 days)
            </label>
          </div>
          <div className="col-3  mt-4 ms-3">
            <button
              type="button"
              class="btn btn btn-primary filter-btn mt-0"
              onClick={filterReset}
            >
              Clear Filter
            </button>
          </div> */}
        </div>
        <div className="student_table">
          <TableComm
            loading={loading}
            columns={columns}
            defaultId={"pk_student_id"}
            data={records}
            //onRowClick={handleStudentViewClick}
            //hiddenColumns={['visibility', 'student_key']}
          />
          
        </div>

      </Container>
    </div>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// };

export default withTranslation()(StudentList)
